import React from "react";

export function UnpinnedVideoLayout({ videos }: { videos: React.ReactNode[] }) {
  const numberOfVideoTracks = videos.length;
  const numberOfColumns = Math.ceil(Math.sqrt(numberOfVideoTracks));

  return (
    <div
      className={`flex-1 grid auto-cols-min gap-2 p-2 grid-flow-row-dense`}
      style={{
        gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))`,
      }}
    >
      {videos}
    </div>
  );
}
