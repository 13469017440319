import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { Label } from "../../components/Label";
import { Spacer } from "../../components/Spacer";
import { TextInput } from "../../components/TextInput";

export const JoinView = () => {
  const navigate = useNavigate();
  const [roomName, setRoomName] = useState("");
  const [secret, setSecret] = useState("");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    navigate(`/configure/${roomName}/${secret}`, { replace: true });
  };

  return (
    <div className="items-center flex bg-background-primary h-screen justify-center">
      <form
        className="flex flex-col border rounded-2xl sm:w-2/3 w-11/12 justify-center items-center sm:p-10 py-10 border-primary-disabledDarkColor "
        onSubmit={onSubmit}
      >
        <img src="/images/logo.png" alt="logo" className="sm:w-1/3 w-7/12" />
        <Spacer />
        <p className="font-bold text-xl w-11/12  sm:w-1/2 text-center">
          Join meeting by entering room details
        </p>
        <Spacer />
        <div className="w-11/12  sm:w-1/2 flex flex-col items-center">
          <Label>Room name</Label>
          <TextInput
            onChangeText={setRoomName}
            value={roomName}
            className="w-3/4"
          />
          <Spacer />
          <Label>Room secret</Label>
          <TextInput
            onChangeText={setSecret}
            value={secret}
            className="w-3/4"
          />

          <Spacer />

          <Button className="w-3/4">Join</Button>
        </div>
      </form>
    </div>
  );
};
