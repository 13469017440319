import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store/store";
import JWTDecode from "jwt-decode";
import dayjs from "dayjs";

export interface VideoCallState {
  roomName: string | null;
  localParticipantName: string | null;
  remoteParticipantName: string | null;
  accessToken: string | null;
  secret: string | null;
  accessTokenRequest: {
    status: "idle" | "loading" | "error" | "success";
    errorMessage: string | undefined;
  };
}

const initialState: VideoCallState = {
  roomName: null,
  localParticipantName: null,
  remoteParticipantName: null,
  accessToken: null,
  secret: null,
  accessTokenRequest: {
    status: "idle",
    errorMessage: undefined,
  },
};

export const fetchAccessToken = createAsyncThunk(
  "video-call/getAccessToken",
  async (
    {
      roomId,
      secret,
      userParam,
    }: { roomId: string; secret: string; userParam: boolean | undefined },
    { rejectWithValue }
  ) => {
    const accessTokenLocalStorageKey = `tat[${roomId}]`;
    const usernameLocalStorageKey = `tun[${roomId}]`;

    try {
      if (!process.env.REACT_APP_TWILIO_ACCESS_TOKEN_X_KEY)
        throw new Error(
          "Missing REACT_APP_TWILIO_ACCESS_TOKEN_X_KEY in .env file"
        );
      if (!process.env.REACT_APP_TWILIO_ACCESS_TOKEN_URL)
        throw new Error(
          "Missing REACT_APP_TWILIO_ACCESS_TOKEN_URL in .env file"
        );

      if (!process.env.REACT_APP_TWILIO_ACCESS_TOKEN_APIM_KEY)
        throw new Error(
          "Missing REACT_APP_TWILIO_ACCESS_TOKEN_URL in .env file"
        );

      // check if there is an access token in async storage for current room and current secret

      const localStorageAccessToken = localStorage.getItem(
        accessTokenLocalStorageKey
      );
      const localStorageUsername = localStorage.getItem(
        usernameLocalStorageKey
      );
      if (localStorageAccessToken) {
        // check if token is valid
        var decodedJWT = JWTDecode<{ exp: number }>(localStorageAccessToken);
        const expiry = dayjs(decodedJWT.exp * 1000);
        const isJWTValid = expiry.isAfter(dayjs(), "second");
        if (isJWTValid) {
          return {
            accessToken: localStorageAccessToken,
            userFirstName: localStorageUsername,
          };
        } else {
          localStorage.removeItem(accessTokenLocalStorageKey);
          localStorage.removeItem(usernameLocalStorageKey);
        }
      }

      const response = await axios.get<{
        accessToken: string;
        userFirstName: string;
      }>(
        `${
          process.env.REACT_APP_TWILIO_ACCESS_TOKEN_URL
        }/v1/rooms/${roomId}/token/${secret}${!!userParam ? "?user=true" : ""}`,
        {
          headers: {
            "x-functions-key": process.env.REACT_APP_TWILIO_ACCESS_TOKEN_X_KEY,
            "Ocp-Apim-Subscription-Key":
              process.env.REACT_APP_TWILIO_ACCESS_TOKEN_APIM_KEY,
          },
        }
      );

      localStorage.setItem(
        accessTokenLocalStorageKey,
        response.data.accessToken
      );
      localStorage.setItem(
        usernameLocalStorageKey,
        response.data.userFirstName
      );
      return response.data;
    } catch (error) {
      localStorage.removeItem(accessTokenLocalStorageKey);
      localStorage.removeItem(usernameLocalStorageKey);
      return rejectWithValue(
        typeof error === "string" ? error : (error as any)?.message
      );
    }
  }
);

export const videoCallSlice = createSlice({
  name: "videoCall",
  initialState,
  reducers: {
    setRoomName: (
      state,
      action: PayloadAction<{ roomName: string | null }>
    ) => {
      state.roomName = action.payload.roomName;
    },
    setSecret: (state, action: PayloadAction<{ secret: string | null }>) => {
      state.secret = action.payload.secret;
    },
    setAccessToken: (
      state,
      action: PayloadAction<{ accessToken: string | null }>
    ) => {
      state.accessToken = action.payload.accessToken;
    },
    setLocalParticipantName: (
      state,
      action: PayloadAction<{ localParticipantName: string | null }>
    ) => {
      state.localParticipantName = action.payload.localParticipantName;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessToken.fulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.remoteParticipantName = payload.userFirstName;
        state.accessTokenRequest.status = "success";
        state.accessTokenRequest.errorMessage = undefined;
      })
      .addCase(fetchAccessToken.rejected, (state, action) => {
        // get error from payload or from generic `error` that is found in the action
        state.accessTokenRequest.errorMessage =
          typeof action.payload === "string"
            ? action.payload
            : action.error.message;
        state.accessTokenRequest.status = "error";
      })
      .addCase(fetchAccessToken.pending, (state) => {
        state.accessTokenRequest.status = "loading";
        state.accessTokenRequest.errorMessage = undefined;
      });
  },
});

export const {
  setRoomName,
  setAccessToken,
  setLocalParticipantName,
  setSecret,
} = videoCallSlice.actions;

export const selectRoomName = (state: RootState) => state.videoCall.roomName;
export const selectSecret = (state: RootState) => state.videoCall.secret;

export const selectLocalParticipantName = (state: RootState) =>
  state.videoCall.localParticipantName;

export const selectRemoteParticipantName = (state: RootState) =>
  state.videoCall.remoteParticipantName;
export const selectAccessToken = (state: RootState) =>
  state.videoCall.accessToken;

export const selectAccessTokenRequest = (state: RootState) =>
  state.videoCall.accessTokenRequest;

export default videoCallSlice.reducer;
