import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

export const useAVDevices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);

  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [arePermissionsGranted, setArePermissionsGranted] =
    useState<boolean>(false);

  const videoInputDevices = devices.filter(
    (device) => device.kind === "videoinput"
  );
  const audioInputDevices = devices.filter(
    (device) => device.kind === "audioinput"
  );
  const audioOutputDevices = devices.filter(
    (device) => device.kind === "audiooutput"
  );

  const askPermission = useCallback(
    async function askPermission({
      facingMode,
    }: {
      facingMode: null | "environment" | "user";
    }): Promise<boolean> {
      try {
        setIsLoadingPermissions(true);
        await navigator.mediaDevices.getUserMedia({
          video:
            isMobile && facingMode === "environment"
              ? { facingMode: "environment" }
              : true,
          audio: true,
        });

        setArePermissionsGranted(true);
        setIsLoadingPermissions(false);
        return true;
      } catch (error) {
        setIsLoadingPermissions(false);
        setArePermissionsGranted(false);
        console.log(error);
        return false;
      }
    },
    []
    /**
     * facingMode is environment for back facing camera.
     * For user facing camera, switching camera is done with regular getUserMedia({video:true})
     */
  );

  const getMediaDevices = useCallback(
    async function getMediaDevices() {
      if (!arePermissionsGranted) {
        console.warn(
          "getMediaDevices() called before permission granted. Make sure to askPermission before calling getMediaDevices()"
        );
        return;
      }
      setIsLoading(true);

      const devices = await navigator.mediaDevices.enumerateDevices();

      setDevices(devices);
      setIsLoading(false);
    },
    [arePermissionsGranted]
  );

  const onDeviceChange = useCallback((): void => {
    getMediaDevices();
  }, [getMediaDevices]);

  useEffect(() => {
    if (!arePermissionsGranted) return;
    navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);
    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        onDeviceChange
      );
    };
  }, [getMediaDevices, arePermissionsGranted, onDeviceChange]);

  return {
    arePermissionsGranted,
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    isLoading,
    getMediaDevices,
    askPermission,
    isLoadingPermissions,
  };
};
