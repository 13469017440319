import { useEffect, useState } from "react";

export function useWindowInnerHeight() {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    function onResize() {
      setInnerHeight(window.innerHeight);
    }
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  });

  return innerHeight;
}
