import { useMemo } from "react";

export const useIsAudioOutputConfigurable = () => {
  const isAudioOutputConfigurable = useMemo(() => {
    const audio = document.createElement("audio");
    // @ts-ignore:next-line
    return typeof audio.setSinkId === "function";
  }, []);

  return isAudioOutputConfigurable;
};
