import { useEffect, useRef, useState } from "react";
import { RemoteParticipant, RemoteVideoTrack } from "twilio-video";
import { Icon } from "../../components/Icon";

export const RemoteVideoStreamView = ({
  videoTrack,
  remoteParticipant,
  isMuted,
  onClick,
  remoteParticipantName,
}: {
  videoTrack: RemoteVideoTrack | null;
  isMuted: boolean;
  remoteParticipant: RemoteParticipant;
  onClick: React.DOMAttributes<HTMLDivElement>["onClick"];
  remoteParticipantName: string | null;
}) => {
  const remoteVideoElement = useRef<HTMLVideoElement | null>(null);
  const [participantNetworkQuality, setParticipantNetworkQuality] = useState(5);

  useEffect(() => {
    if (!videoTrack || !remoteVideoElement.current) return;
    videoTrack?.attach(remoteVideoElement.current);
    const remoteVideoElementCopy = Object.assign(remoteVideoElement.current);
    return () => {
      videoTrack?.detach(remoteVideoElementCopy);
    };
  }, [videoTrack]);

  const networkQuality = (quality: number) => {
    setParticipantNetworkQuality(quality);
  };

  useEffect(() => {
    // Print changes to Network Quality Level and statistics
    remoteParticipant.on("networkQualityLevelChanged", networkQuality);

    // Print changes to Network Quality Level and statistics
    return () => {
      remoteParticipant.removeListener(
        "networkQualityLevelChanged",
        networkQuality
      );
    };
  }, []);

  const shouldShowPoorNetworkQualityIndication = participantNetworkQuality < 2;

  return (
    <div className="w-full h-max-full relative flex-1" onClick={onClick}>
      <div className="absolute max-h-full h-full w-full top-0 left-0 flex content-center">
        <div className="absolute top-1/2 left-1/2 bottom-1/2 right-1/2 flex flex-row">
          {isMuted && (
            <div className="px-2">
              <Icon name="microphone-slash" size={36} />
            </div>
          )}
          {shouldShowPoorNetworkQualityIndication && (
            <div className="px-2">
              <Icon name="poor-connection" size={36} />
            </div>
          )}
        </div>

        <video
          ref={remoteVideoElement}
          className="object-contain flex-1"
        ></video>
        {!!remoteParticipantName && (
          <div
            className="bg-white absolute bottom-0 p-2 pl-5 pr-5 right-2 left-auto rounded-md"
            style={{ boxShadow: "-1px 0 3px white" }}
          >
            <p className="text-black md:text-md text-sm">
              {remoteParticipantName}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
