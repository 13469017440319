import React from "react";

interface TextInputInterface
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string | number | undefined;

  onChangeText: (newValue: string) => void;
}

export const TextInput = ({
  value,

  onChangeText,
  className,
  ...otherProps
}: TextInputInterface) => {
  return (
    <input
      onChange={(event) => onChangeText(event.target.value)}
      className={`p-2 text-primary-headerTextColor border border-solid border-primary-disabledDarkColor rounded text-sm ${className}`}
      value={value}
      {...otherProps}
    />
  );
};
