import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { isDevelopment } from "../../utils/isDevelopment";

export interface ConfigurationState {
  selectedAudioOutputDeviceId: string | null;
  selectedAudioInputDeviceId: string | null;
  selectedVideoInputDeviceId: string | null;
  isAudioInputDisabled: boolean;
  isVideoInputDisabled: boolean;
  facingMode: null | "user" | "environment";
}

const initialState: ConfigurationState = {
  selectedAudioOutputDeviceId: null,
  selectedAudioInputDeviceId: null,
  selectedVideoInputDeviceId: null,
  isAudioInputDisabled: isDevelopment ? true : false,
  isVideoInputDisabled: false,
  facingMode: null,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,

  reducers: {
    setSelectedAudioOutputDeviceId: (
      state,
      action: PayloadAction<{ selectedAudioOutputDeviceId: string | null }>
    ) => {
      state.selectedAudioOutputDeviceId =
        action.payload.selectedAudioOutputDeviceId;
    },
    setSelectedAudioInputDeviceId: (
      state,
      action: PayloadAction<{ selectedAudioInputDeviceId: string | null }>
    ) => {
      state.selectedAudioInputDeviceId =
        action.payload.selectedAudioInputDeviceId;
    },
    setSelectedVideoInputDeviceId: (
      state,
      action: PayloadAction<{ selectedVideoInputDeviceId: string | null }>
    ) => {
      state.selectedVideoInputDeviceId =
        action.payload.selectedVideoInputDeviceId;
    },
    setIsAudioInputDisabled: (
      state,
      action: PayloadAction<{ isAudioInputDisabled: boolean }>
    ) => {
      state.isAudioInputDisabled = action.payload.isAudioInputDisabled;
    },
    setIsVideoInputDisabled: (
      state,
      action: PayloadAction<{ isVideoInputDisabled: boolean }>
    ) => {
      state.isVideoInputDisabled = action.payload.isVideoInputDisabled;
    },
    setFacingMode: (
      state,
      action: PayloadAction<{ facingMode: ConfigurationState["facingMode"] }>
    ) => {
      state.facingMode = action.payload.facingMode;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedAudioOutputDeviceId,
  setSelectedAudioInputDeviceId,
  setSelectedVideoInputDeviceId,
  setIsAudioInputDisabled,
  setIsVideoInputDisabled,
  setFacingMode,
} = configurationSlice.actions;

export const selectConfiguration = (state: RootState) => state.configuration;

export default configurationSlice.reducer;
