import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { Spacer } from "../../components/Spacer";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectRoomName, selectSecret } from "../videocall/videoCallSlice";

export const CallEndedView = () => {
  const roomName = useAppSelector(selectRoomName);
  const navigate = useNavigate();
  const roomSecret = useAppSelector(selectSecret);

  const onRejoinPress = () => {
    const linkTo =
      roomName && roomSecret
        ? `/configure/${roomName}/${roomSecret}?user=true`
        : "/";
    navigate(linkTo, { replace: true });
  };

  return (
    <div className="h-screen w-screen flex center-content items-center justify-center flex-col bg-background-primary">
      <img
        src="/images/logo.png"
        alt="logo"
        className="h-[100px] object-contain"
      />
      <Spacer />
      <p className="text-2xl font-bold">You have left the meeting</p>
      <Spacer />
      <div className="flex flex-row">
        <Button onClick={onRejoinPress}>Rejoin</Button>
      </div>
    </div>
  );
};
