import { useEffect, useRef } from "react";
import { RemoteAudioTrack } from "twilio-video";

export const RemoteAudioStreamView = ({
  audioTrack,
}: {
  audioTrack: RemoteAudioTrack;
}) => {
  const remoteAudioElement = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!audioTrack || !remoteAudioElement.current) return;
    audioTrack?.attach(remoteAudioElement.current);
    const remoteAudioElementCopy = Object.assign(remoteAudioElement.current);
    return () => {
      audioTrack?.detach(remoteAudioElementCopy);
    };
  }, [audioTrack]);

  return <audio ref={remoteAudioElement}></audio>;
};
