import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { DropDown } from "../../components/Dropdown";
import { Icon } from "../../components/Icon";
import { Label } from "../../components/Label";
import { Spinner } from "../../components/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useIsAudioOutputConfigurable } from "../../hooks/useIsAudioOutputConfigurable";
import { useIsScreenSharingAvailable } from "../../hooks/useIsScreenSharingAvailable";
import { useLocationSearchParams } from "../../hooks/useLocationSearchParams";
import { useTheme } from "../../hooks/useTheme";
import { isDevelopment } from "../../utils/isDevelopment";
import {
  selectConfiguration,
  setFacingMode,
  setIsAudioInputDisabled,
  setIsVideoInputDisabled,
  setSelectedAudioInputDeviceId,
  setSelectedAudioOutputDeviceId,
  setSelectedVideoInputDeviceId,
} from "../configuration/configurationSlice";
import { ActionButton } from "./ActionButton";
import { LocalVideoStreamView } from "./LocalVideoStreamView";
import { PinnedVideoLayout } from "./PinnedVideoLayout";
import { RemoteAudioStreamView } from "./RemoteAudioStreamView";
import { RemoteVideoStreamView } from "./RemoteVideoStreamView";
import { UnpinnedVideoLayout } from "./UnpinnedVideoLayout";
import { useAVDevices } from "./useAVDevices";
import { useShareScreen } from "./useShareScreen";
import { useVideoCall } from "./useVideoCall";
import { useWindowInnerHeight } from "../../hooks/useWindowInnerHeight";
import {
  fetchAccessToken,
  selectAccessToken,
  selectAccessTokenRequest,
  selectRemoteParticipantName,
  selectRoomName,
  selectSecret,
  setRoomName,
  setSecret,
} from "./videoCallSlice";

export function VideoCallView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const innerHeight = useWindowInnerHeight();

  const locationSearchParams = useLocationSearchParams();
  const [focusedTrack, setFocusedTrack] = useState<string | null>(null);

  const roomName = useAppSelector(selectRoomName);
  const accessToken = useAppSelector(selectAccessToken);
  const remoteParticipantName = useAppSelector(selectRemoteParticipantName);
  const secret = useAppSelector(selectSecret);
  const accessTokenRequest = useAppSelector(selectAccessTokenRequest);

  const isAudioOutputConfigurable = useIsAudioOutputConfigurable();

  const {
    isAudioInputDisabled,
    isVideoInputDisabled,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    selectedVideoInputDeviceId,
    facingMode,
  } = useAppSelector(selectConfiguration);

  const theme = useTheme();

  const [isLogVisible, setIsLogVisible] = useState<boolean>(false);
  const [areSettingsVisible, setAreSettingsVisible] = useState(false);
  const [log, setLog] = useState<string[]>([]);
  const addToLog = useCallback((text: string) => {
    const time = dayjs().format("HH:mm:ss[.]SSS");
    setLog((log) => [`${time} - ${text}`, ...log]);
  }, []);

  const { roomName: roomNameParam, secret: secretParam } = useParams();

  const isScreenSharingAvailable = useIsScreenSharingAvailable();

  const {
    startStream: startScreenStream,
    stopStream: stopScreenStream,
    screenStream,
  } = useShareScreen();

  const {
    connect,
    disconnect,
    state,
    localParticipant,
    remoteParticipants,
    remoteVideoTracks,
    remoteAudioTracks,
    mutedParticipants,
    twilioRoomState,
    isReady,
    isLoadingMobileCamera,
  } = useVideoCall({
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    selectedVideoInputDeviceId,
    addToLog,
    isAudioInputDisabled,
    isVideoInputDisabled,
    screenStream,
    facingMode,
  });

  useEffect(() => {
    // used only for debug purpose
    // leave here as fallback
    const accessTokenParam = locationSearchParams.get("token");
    if (accessTokenParam && isReady) {
      dispatch({
        type: fetchAccessToken.fulfilled.type,
        payload: { accessToken: accessTokenParam },
      });
    }
  }, [dispatch, locationSearchParams, isReady]);

  useEffect(() => {
    if (roomNameParam && secretParam) {
      dispatch(setRoomName({ roomName: roomNameParam }));
      dispatch(setSecret({ secret: secretParam }));
    } else {
      navigate("/join", { replace: true });
    }
  }, [dispatch, navigate, roomNameParam, secretParam]);

  const {
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    isLoading: isLoadingAVDevices,
    askPermission,
    getMediaDevices,
  } = useAVDevices();

  useEffect(() => {
    askPermission({ facingMode }).then((permissionsGranted) => {
      if (permissionsGranted) {
        getMediaDevices();
      } else {
        navigate(`/configure/${roomNameParam}`, { replace: true });
      }
    });
  }, [askPermission, getMediaDevices, navigate, roomNameParam]);

  useEffect(() => {
    const accessTokenParam = locationSearchParams.get("token");
    if (!!roomName && !!secret && !accessTokenParam) {
      const userParam = locationSearchParams.get("user");
      // twilio has limitation that only one identity (found from twilio accessToken retrieved from the backend) can be in the room.
      // if userParam is true, then when sending request to get twilio accessToken, if user=true is sent as param, it will randomly generate users identity
      // if user=true is not sent, then generated twilio accessTokens will have same identity
      dispatch(
        fetchAccessToken({
          roomId: roomName,
          secret,
          userParam: userParam === "true" ? true : undefined,
        })
      );
    }
    return () => {
      disconnect();
    };
  }, [roomName, secret]);

  useEffect(() => {
    if (!!roomName && !!accessToken && isReady) {
      connect({ roomName, accessToken });
    }
    return () => {
      disconnect();
    };
  }, [roomName, accessToken, isReady]);

  useEffect(() => {
    // set initial values for IO devices
    if (isLoadingAVDevices) return;
    if (!selectedAudioInputDeviceId && audioInputDevices.length) {
      dispatch(
        setSelectedAudioInputDeviceId({
          selectedAudioInputDeviceId: audioInputDevices[0].deviceId,
        })
      );
    }
    if (!selectedAudioOutputDeviceId && audioOutputDevices.length) {
      dispatch(
        setSelectedAudioOutputDeviceId({
          selectedAudioOutputDeviceId: audioOutputDevices[0].deviceId,
        })
      );
    }
    if (!selectedVideoInputDeviceId && videoInputDevices.length) {
      dispatch(
        setSelectedVideoInputDeviceId({
          selectedVideoInputDeviceId: videoInputDevices[0].deviceId,
        })
      );
    }
  }, [
    audioInputDevices,
    audioOutputDevices,
    isLoadingAVDevices,
    videoInputDevices,
    dispatch,
  ]);

  if (!roomName) {
    return (
      <div>
        <h2>Missing room</h2>
      </div>
    );
  }

  const isConnected =
    accessTokenRequest.status === "success" &&
    state === "connected" &&
    twilioRoomState === "connected";

  const isWaitingParticipants = isConnected && !remoteParticipants?.size;

  function renderRemoteVideoStream(videoTrack: typeof remoteVideoTracks[0]) {
    return (
      <RemoteVideoStreamView
        remoteParticipantName={remoteParticipantName}
        remoteParticipant={videoTrack.participant}
        isMuted={mutedParticipants.some(
          (participant) => participant.sid === videoTrack.participant.sid
        )}
        videoTrack={videoTrack.track}
        key={videoTrack.track.sid}
        onClick={() => {
          if (remoteVideoTracks?.length <= 1) return;
          setFocusedTrack((id) =>
            id === videoTrack.track.sid ? null : videoTrack.track.sid
          );
        }}
      />
    );
  }

  return (
    <div className="relative flex flex-col" style={{ height: innerHeight }}>
      <div className="flex flex-1 relative bg-black">
        {state === "error" || accessTokenRequest.status === "error" ? (
          <div className="justify-center items-center flex-col self-center flex-1 flex">
            <p className="text-center p-6 text-white">
              An error occurred while connecting to the room.
            </p>
            <p className="text-center p-6 text-white">
              Please try again in a few minutes.
            </p>
          </div>
        ) : !isConnected ? (
          <div className="justify-center items-center flex-col self-center flex-1 flex">
            <p className="text-center p-6 text-white">Connecting...</p>
            <Spinner />
          </div>
        ) : isWaitingParticipants ? (
          <div className="justify-center items-center flex-col self-center flex-1 flex">
            <p className="text-center p-6 text-white">
              Waiting for participants...
            </p>
            <Spinner />
          </div>
        ) : focusedTrack ? (
          <PinnedVideoLayout
            pinnedVideo={remoteVideoTracks
              .filter((videoTrack) => focusedTrack === videoTrack.track.sid)
              .map(renderRemoteVideoStream)}
            otherVideos={remoteVideoTracks
              .filter((videoTrack) => focusedTrack !== videoTrack.track.sid)
              .map(renderRemoteVideoStream)}
          />
        ) : (
          <UnpinnedVideoLayout
            videos={remoteVideoTracks.map(renderRemoteVideoStream)}
          />
        )}

        {remoteAudioTracks.map(({ track }) => {
          return <RemoteAudioStreamView audioTrack={track} key={track.sid} />;
        })}

        <div className="absolute top-4 right-4 left-auto bottom-auto w-1/6">
          <LocalVideoStreamView
            showVideoControls={false}
            videoTrack={localParticipant.videoTrack}
            isVideoInputDisabled={isVideoInputDisabled}
            isAudioInputDisabled={isAudioInputDisabled}
            onDisableAudioInputPress={() => {}}
            onDisableVideoInputPress={() => {}}
            participant={localParticipant.localParticipant}
          />
        </div>
      </div>

      <div className="bg-white flex p-3 flex-col ">
        <div className="flex">
          <div className="flex-1">
            <img
              src="/images/logo.png"
              alt="logo"
              className="h-[50px] object-contain hidden sm:inline"
            />
          </div>
          <div className="flex justify-center flex-2">
            {isScreenSharingAvailable && (
              <div className="px-2">
                <ActionButton
                  iconName={
                    !!screenStream ? "share-screen-slash" : "share-screen"
                  }
                  onClick={() => {
                    if (!screenStream) {
                      startScreenStream();
                    } else {
                      stopScreenStream();
                    }
                  }}
                />
              </div>
            )}
            <div className="px-2">
              <ActionButton
                iconName={
                  isAudioInputDisabled ? "microphone-slash" : "microphone"
                }
                onClick={() => {
                  dispatch(
                    setIsAudioInputDisabled({
                      isAudioInputDisabled: !isAudioInputDisabled,
                    })
                  );
                }}
              />
            </div>
            <div className="px-2">
              <ActionButton
                iconName={isVideoInputDisabled ? "video-slash" : "video"}
                onClick={() => {
                  dispatch(
                    setIsVideoInputDisabled({
                      isVideoInputDisabled: !isVideoInputDisabled,
                    })
                  );
                }}
              />
            </div>
            <div className="px-2 relative">
              <ActionButton
                iconName="settings"
                onClick={() => {
                  setAreSettingsVisible((visible) => !visible);
                }}
              />
              {areSettingsVisible && (
                <div className="absolute bottom-[70px] left-[-118px] right-0 bg-white rounded-lg shadow-lg p-2 w-[300px]">
                  <div className="flex justify-evenly p-2 flex-col">
                    <div className="flex flex-row">
                      <p className="font-bold">Audio and video settings</p>
                      <div className="flex-1"></div>
                      <button
                        onClick={() => {
                          setAreSettingsVisible(false);
                        }}
                      >
                        <Icon
                          name="close"
                          color={theme.theme.colors.primary.disabledDarkColor}
                          size={22}
                        />
                      </button>
                    </div>
                    <div className="py-2">
                      {isMobile ? (
                        <div className="py-2 flex flex-col">
                          <Label>Camera</Label>
                          <Button
                            onClick={() => {
                              dispatch(
                                setFacingMode({
                                  facingMode:
                                    facingMode === "environment"
                                      ? "user"
                                      : "environment",
                                })
                              );
                            }}
                            disabled={isLoadingMobileCamera}
                          >
                            <div className="flex flex-row justify-center items-center ">
                              Switch camera
                              {isLoadingMobileCamera && (
                                <div className="absolute w-full h-full flex items-center justify-center">
                                  <Spinner size={18} />
                                </div>
                              )}
                            </div>
                          </Button>
                        </div>
                      ) : (
                        <DropDown
                          onChange={(newValue) => {
                            dispatch(
                              setSelectedVideoInputDeviceId({
                                selectedVideoInputDeviceId: newValue,
                              })
                            );
                          }}
                          value={selectedVideoInputDeviceId}
                          options={videoInputDevices.map((device) => ({
                            label: device.label,
                            value: device.deviceId,
                          }))}
                          label={"Camera"}
                        />
                      )}
                    </div>

                    <div className="py-2">
                      <DropDown
                        onChange={(newValue) => {
                          dispatch(
                            setSelectedAudioInputDeviceId({
                              selectedAudioInputDeviceId: newValue,
                            })
                          );
                        }}
                        value={selectedAudioInputDeviceId}
                        options={audioInputDevices.map((device) => ({
                          label: device.label,
                          value: device.deviceId,
                        }))}
                        label={"Microphone"}
                      />
                    </div>

                    {isAudioOutputConfigurable && (
                      <div className="py-2">
                        <DropDown
                          onChange={(newValue) => {
                            dispatch(
                              setSelectedAudioOutputDeviceId({
                                selectedAudioOutputDeviceId: newValue,
                              })
                            );
                          }}
                          value={selectedAudioOutputDeviceId}
                          options={audioOutputDevices.map((device) => ({
                            label: device.label,
                            value: device.deviceId,
                          }))}
                          label={"Speakers"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="px-2">
              <ActionButton
                iconName="phone"
                onClick={() => {
                  disconnect();
                  navigate("/call-ended", { replace: true });
                }}
              />
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>

      {isDevelopment && (
        <div className="absolute top-0 left-0 bg-white max-h-52 overflow-y-scroll opacity-90">
          <div>
            <Button
              onClick={() => {
                setIsLogVisible((v) => !v);
              }}
              size="small"
            >
              {isLogVisible ? "Hide log" : "Show log"}
            </Button>
          </div>
          {isLogVisible &&
            log.map((l, index) => (
              <p key={index} className="text-xs font-light">
                {l}
              </p>
            ))}
        </div>
      )}
    </div>
  );
}
