import { useEffect, useRef } from "react";
import { LocalParticipant, LocalVideoTrack } from "twilio-video";
import {
  LocalVideoControlsInterface,
  LocalVideoStreamControls,
} from "./LocalVideoStreamControls";

export const LocalVideoStreamView = ({
  videoTrack,
  isVideoInputDisabled,
  isAudioInputDisabled,
  onDisableAudioInputPress,
  onDisableVideoInputPress,
  showVideoControls,
  participant: _participant,
}: {
  videoTrack: LocalVideoTrack | undefined;
  isVideoInputDisabled: boolean;
  isAudioInputDisabled: boolean;
  showVideoControls: boolean;
  participant?: LocalParticipant | undefined;
} & LocalVideoControlsInterface) => {
  const localVideoElement = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!videoTrack || !localVideoElement.current) return;
    videoTrack.attach(localVideoElement.current);
    return () => {
      if (!localVideoElement.current) return;
      videoTrack.detach(localVideoElement.current);
    };
  }, [videoTrack]);

  return (
    <figure className="relative h-full">
      <video
        ref={localVideoElement}
        className="object-contain mx-auto mirrorize"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      ></video>
      {showVideoControls && (
        <div className="absolute bottom-0 z-10 right-0 left-0 flex items-center">
          <LocalVideoStreamControls
            onDisableAudioInputPress={onDisableAudioInputPress}
            onDisableVideoInputPress={onDisableVideoInputPress}
            isAudioInputDisabled={isAudioInputDisabled}
            isVideoInputDisabled={isVideoInputDisabled}
          />
        </div>
      )}
    </figure>
  );
};
