import { Route, Routes } from "react-router-dom";
import { CallEndedPage } from "../pages/CallEndedPage";
import { ConfigurePage } from "../pages/ConfigurePage";
import { JoinPage } from "../pages/JoinPage";
import { VideoCallPage } from "../pages/VideoCallPage";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<JoinPage />} />
      <Route path="/join" element={<JoinPage />} />
      <Route path="/configure">
        <Route path=":roomName" element={<ConfigurePage />}>
          <Route path=":secret" element={<ConfigurePage />}></Route>
          <Route path="" element={<ConfigurePage />}></Route>
        </Route>
        <Route path="" element={<ConfigurePage />}></Route>
      </Route>
      <Route path="/video-call">
        <Route path=":roomName" element={<VideoCallPage />}>
          <Route path=":secret" element={<VideoCallPage />}></Route>
          <Route path="" element={<VideoCallPage />}></Route>
        </Route>
        <Route path="" element={<VideoCallPage />}></Route>
      </Route>
      <Route path="/call-ended" element={<CallEndedPage />} />
    </Routes>
  );
}
