import React from "react";
import { Label } from "./Label";
import "./Dropdown.css";

export interface DropDownInterface {
  options: {
    label: string | number;
    value: string | number;
  }[];
  value: string | number | null;
  label: string;
  onChange: (newValue: string) => void;
}

export const DropDown = ({
  options,
  value,
  label,
  onChange,
}: DropDownInterface) => {
  return (
    <>
      <div className="flex flex-col">
        <Label>{label}</Label>
        <select
          onChange={(event) => onChange(event.target.value)}
          className="p-2 text-primary-headerTextColor border border-solid border-primary-disabledDarkColor rounded text-sm"
          value={value || ""}
        >
          {options.map(({ label, value }) => {
            return (
              <option value={value} key={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};
