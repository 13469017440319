import { configureStore } from "@reduxjs/toolkit";

import configurationSlice from "../features/configuration/configurationSlice";
import videoCallSlice from "../features/videocall/videoCallSlice";

import * as Redux from "redux";

import reduxLogger from "redux-logger";
import { isDevelopment } from "../utils/isDevelopment";

const reduxMiddlewares: Redux.Middleware[] = [];
if (isDevelopment) {
  reduxMiddlewares.push(reduxLogger);
}

export const store = configureStore({
  reducer: {
    videoCall: videoCallSlice,
    configuration: configurationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...reduxMiddlewares),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
