import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createLocalVideoTrack, LocalVideoTrack } from "twilio-video";
import { Button } from "../../components/Button";
import { DropDown } from "../../components/Dropdown";
import { Spacer } from "../../components/Spacer";
import { Spinner } from "../../components/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useIsAudioOutputConfigurable } from "../../hooks/useIsAudioOutputConfigurable";
import { useLocationSearchParams } from "../../hooks/useLocationSearchParams";
import { useWindowInnerHeight } from "../../hooks/useWindowInnerHeight";
import {
  selectConfiguration,
  setFacingMode,
  setIsAudioInputDisabled,
  setIsVideoInputDisabled,
  setSelectedAudioInputDeviceId,
  setSelectedAudioOutputDeviceId,
  setSelectedVideoInputDeviceId,
} from "../configuration/configurationSlice";
import { LocalVideoStreamView } from "../videocall/LocalVideoStreamView";
import { useAVDevices } from "../videocall/useAVDevices";
import {
  selectRoomName,
  selectSecret,
  setRoomName,
  setSecret,
} from "../videocall/videoCallSlice";

import { isMobile } from "react-device-detect";
import { Label } from "../../components/Label";

export const ConfigureView = () => {
  const innerHeight = useWindowInnerHeight();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const configuration = useAppSelector(selectConfiguration);
  const locationSearchParams = useLocationSearchParams();

  const roomName = useSelector(selectRoomName);
  const secret = useSelector(selectSecret);
  const isAudioOutputConfigurable = useIsAudioOutputConfigurable();

  const [localVideoTrack, setLocalVideoTrack] = useState<
    LocalVideoTrack | undefined
  >(undefined);

  const [isLoadingCamera, setIsLoadingCamera] = useState(true);

  const {
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    isLoading: isLoadingAVDevices,
    askPermission,
    getMediaDevices,
    isLoadingPermissions,
    arePermissionsGranted,
  } = useAVDevices();

  const { roomName: roomNameParam, secret: secretParam } = useParams();

  const {
    isAudioInputDisabled,
    isVideoInputDisabled,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    selectedVideoInputDeviceId,
    facingMode,
  } = configuration;

  useEffect(() => {
    if (!roomNameParam || !secretParam) {
      navigate("/", { replace: true });
    } else {
      dispatch(setRoomName({ roomName: roomNameParam }));
      dispatch(setSecret({ secret: secretParam }));
    }
  }, []);

  useEffect(() => {
    if (arePermissionsGranted) {
      askPermission({ facingMode: null }).then(() => getMediaDevices());
    } else {
      askPermission({ facingMode: null });
    }
  }, [arePermissionsGranted, askPermission, getMediaDevices]);

  useEffect(() => {
    // set initial values for mic
    if (isLoadingAVDevices) return; // TODO:  wrong because initial value is false
    if (!selectedAudioInputDeviceId && audioInputDevices.length) {
      dispatch(
        setSelectedAudioInputDeviceId({
          selectedAudioInputDeviceId: audioInputDevices[0].deviceId,
        })
      );
    }
    if (!selectedAudioOutputDeviceId && audioOutputDevices.length) {
      dispatch(
        setSelectedAudioOutputDeviceId({
          selectedAudioOutputDeviceId: audioOutputDevices[0].deviceId,
        })
      );
    }
    if (!selectedVideoInputDeviceId && videoInputDevices.length) {
      dispatch(
        setSelectedVideoInputDeviceId({
          selectedVideoInputDeviceId: videoInputDevices[0].deviceId,
        })
      );
    }
  }, [
    audioInputDevices,
    audioOutputDevices,
    isLoadingAVDevices,
    videoInputDevices,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    selectedVideoInputDeviceId,
    dispatch,
  ]);

  useEffect(() => {
    async function changeLocalVideoStream() {
      if (!selectedVideoInputDeviceId) return;
      setIsLoadingCamera(true);
      const localVideoTrack = await createLocalVideoTrack({
        deviceId: selectedVideoInputDeviceId,
      });
      if (isVideoInputDisabled) {
        localVideoTrack.disable();
      }
      setLocalVideoTrack(localVideoTrack);
      setIsLoadingCamera(false);
      return localVideoTrack;
    }

    const localVideoTrackk = changeLocalVideoStream();
    return () => {
      localVideoTrackk.then((t) => t?.stop());
    };
  }, [selectedVideoInputDeviceId]);

  useEffect(() => {
    async function restartLocalVideoStream() {
      let isSuccess = false;
      let numberOfRetries = 0;
      /**
       * Explanation for following piece of code.
       * Sometimes, when requiring environment facing camera, sometimes, browser throws an exception.
       * But after some time and numerous retries, we can get access to the environment facing camera.
       * So following do while loop tries to access environment camera every 500ms, and as far as we tested it
       * the max number of retires was 15 before we got access to the camera.
       * Hopefully this will be handled better in the next versions of chrome on mobile devices and this code
       * can be refactored to something better than this.
       */
      do {
        try {
          setIsLoadingCamera(true);
          if (!facingMode) return;
          if (facingMode === "environment") {
            await new Promise((r) => {
              setTimeout(r, 500);
            });
            await localVideoTrack?.restart({
              facingMode,
            });
          } else {
            await localVideoTrack?.restart({
              deviceId: videoInputDevices[0].deviceId,
            });
          }
          isSuccess = true;
        } catch (e) {
          if (numberOfRetries > 20) {
            window.alert(
              "Something went wrong. Please refresh the webpage and try again."
            );
            isSuccess = true;
          }
          numberOfRetries += 1;
          // console.log({ e });
        }
      } while (!isSuccess);
      setIsLoadingCamera(false);
      return localVideoTrack;
    }

    const localVideoTrackk = restartLocalVideoStream();
    return () => {
      localVideoTrackk.then((t) => t?.stop());
    };
  }, [facingMode]);

  useEffect(() => {
    if (isLoadingCamera) {
      document.body.style.pointerEvents = "none";
    } else {
      document.body.style.pointerEvents = "auto";
    }
  }, [isLoadingCamera]);

  useEffect(() => {
    if (isVideoInputDisabled) {
      localVideoTrack?.disable();
    } else {
      localVideoTrack?.enable();
    }
  }, [isVideoInputDisabled]);

  const handleDisableLocalVideoStreamPress = () => {
    dispatch(
      setIsVideoInputDisabled({ isVideoInputDisabled: !isVideoInputDisabled })
    );
  };

  const handleMutePress = () => {
    dispatch(
      setIsAudioInputDisabled({
        isAudioInputDisabled: !configuration.isAudioInputDisabled,
      })
    );
  };

  const handleJoinPress = () => {
    const accessTokenParam = locationSearchParams.get("token");
    const accessToken = !!accessTokenParam ? `?token=${accessTokenParam}` : "";
    // if ?user=true param, forward this param so that is used on video-call screen
    // for what it is used look at the comments in code of video-call view
    const userParam = locationSearchParams.get("user");
    const user =
      userParam === "true" ? (accessToken ? `&user=true` : "?user=true") : "";
    navigate(`/video-call/${roomName}/${secret}${accessToken}${user}`, {
      replace: true,
    });
  };

  return (
    <div
      className="sm:max-w-screen-lg m-auto flex flex-col"
      style={{ height: innerHeight }}
    >
      {!arePermissionsGranted && (
        <div className="flex justify-center flex-col flex-1">
          {isLoadingPermissions && (
            <>
              <div className="flex justify-center">
                <Spinner />
              </div>
            </>
          )}
          {isLoadingPermissions && (
            <p className="text-m text-center">
              In order to access the video call you first must allow this
              webpage to use camera and microphone.
            </p>
          )}

          {!isLoadingPermissions && (
            <p className="text-m text-center">
              You must allow this webpage to use camera and microphone so that
              you could join the call
            </p>
          )}
        </div>
      )}
      {arePermissionsGranted && (
        <div className="flex flex-col justify-center flex-1">
          <div className="p-2 ">
            <img src="/images/logo.png" alt="logo" className="sm:w-1/5 w-1/3" />
          </div>

          <div className="flex sm:flex-row flex-col ">
            <div className="mx-auto h-[40%] sm:h-full sm:flex-1">
              <LocalVideoStreamView
                showVideoControls
                videoTrack={localVideoTrack}
                isVideoInputDisabled={isVideoInputDisabled}
                isAudioInputDisabled={isAudioInputDisabled}
                onDisableAudioInputPress={handleMutePress}
                onDisableVideoInputPress={handleDisableLocalVideoStreamPress}
              />
            </div>

            <div className="p-2 items-end ">
              {!isLoadingAVDevices && (
                <div className="p-4">
                  {isMobile ? (
                    <div className="py-2 flex flex-col">
                      <Label>Camera</Label>
                      <Button
                        onClick={() => {
                          dispatch(
                            setFacingMode({
                              facingMode:
                                facingMode === "environment"
                                  ? "user"
                                  : "environment",
                            })
                          );
                        }}
                        disabled={isLoadingCamera}
                      >
                        <div className="flex flex-row justify-center items-center ">
                          Switch camera
                          {isLoadingCamera && (
                            <div className="absolute w-full h-full flex items-center justify-center">
                              <Spinner size={18} />
                            </div>
                          )}
                        </div>
                      </Button>
                    </div>
                  ) : (
                    <div className="py-2">
                      <DropDown
                        onChange={(newValue) => {
                          dispatch(
                            setSelectedVideoInputDeviceId({
                              selectedVideoInputDeviceId: newValue,
                            })
                          );
                        }}
                        value={selectedVideoInputDeviceId}
                        options={videoInputDevices.map((device) => ({
                          label: device.label,
                          value: device.deviceId,
                        }))}
                        label={"Camera"}
                      />
                    </div>
                  )}

                  <div className="py-2">
                    <DropDown
                      onChange={(newValue) => {
                        dispatch(
                          setSelectedAudioInputDeviceId({
                            selectedAudioInputDeviceId: newValue,
                          })
                        );
                      }}
                      value={selectedAudioInputDeviceId}
                      options={audioInputDevices.map((device) => ({
                        label: device.label,
                        value: device.deviceId,
                      }))}
                      label={"Microphone"}
                    />
                  </div>

                  {isAudioOutputConfigurable && (
                    <div className="py-2">
                      <DropDown
                        onChange={(newValue) => {
                          dispatch(
                            setSelectedAudioOutputDeviceId({
                              selectedAudioOutputDeviceId: newValue,
                            })
                          );
                        }}
                        value={selectedAudioOutputDeviceId}
                        options={audioOutputDevices.map((device) => ({
                          label: device.label,
                          value: device.deviceId,
                        }))}
                        label={"Speakers"}
                      />
                    </div>
                  )}
                  <div className="py-2">
                    <Spacer size="small" />
                    <Button onClick={handleJoinPress} className="w-full">
                      Start
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
