import { useCallback, useState } from "react";

export const useShareScreen = () => {
  const [screenStream, setScreenStream] = useState<MediaStream | undefined>();

  const startStream = useCallback(async function shareScreen(): Promise<
    MediaStream | boolean
  > {
    const newScreenStream = await navigator.mediaDevices.getDisplayMedia();
    newScreenStream.getVideoTracks()[0].onended = stopStream;

    setScreenStream(newScreenStream);
    return newScreenStream;
  },
  []);

  const stopStream = () => {
    screenStream?.getTracks().forEach((stream) => {
      stream.enabled = false;
      stream.stop();
    });

    setScreenStream(undefined);
  };

  return {
    screenStream,
    startStream,
    stopStream,
  };
};
