import React from "react";

export function PinnedVideoLayout({
  pinnedVideo,
  otherVideos,
}: {
  pinnedVideo: React.ReactNode | React.ReactNode[];
  otherVideos: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div className="flex flex-1 flex-col">
      {/* Pinned video */}
      <div className="flex flex-1">{pinnedVideo}</div>

      {/* Other videos */}
      <div className="flex flex-row h-48 justify-between">{otherVideos}</div>
    </div>
  );
}
