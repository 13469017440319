import { useMemo } from "react";
import { useTheme } from "../hooks/useTheme";

export const Spacer = ({
  size = "medium",
}: {
  size?: "small" | "medium" | "large" | "extra-large";
}) => {
  const theme = useTheme();
  const spacing = useMemo(() => {
    if (size === "small") return theme.theme.extend.spacing.s;
    if (size === "medium") return theme.theme.extend.spacing.m;
    if (size === "large") return theme.theme.extend.spacing.l;
    if (size === "extra-large") return theme.theme.extend.spacing.xl;
    return theme.theme.extend.spacing.m;
  }, [size, theme.theme.extend.spacing.m, theme.theme.extend.spacing.s]);

  return <div style={{ width: spacing, height: spacing }}></div>;
};
