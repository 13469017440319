import { ActionButton } from "./ActionButton";

export interface LocalVideoControlsInterface {
  onDisableAudioInputPress: () => void;
  onDisableVideoInputPress: () => void;
}

export const LocalVideoStreamControls = ({
  onDisableAudioInputPress,
  onDisableVideoInputPress,
  isVideoInputDisabled,
  isAudioInputDisabled,
}: {
  isVideoInputDisabled: boolean;
  isAudioInputDisabled: boolean;
} & LocalVideoControlsInterface) => {
  return (
    <div className="flex flex-row justify-self-center p-5 m-auto">
      <div className="px-2">
        <ActionButton
          iconName={isAudioInputDisabled ? "microphone-slash" : "microphone"}
          onClick={onDisableAudioInputPress}
        />
      </div>
      <div className="px-2">
        <ActionButton
          iconName={isVideoInputDisabled ? "video-slash" : "video"}
          onClick={onDisableVideoInputPress}
        />
      </div>
    </div>
  );
};
