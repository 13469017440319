import { Icon, IconProps } from "../../components/Icon";

export const ActionButton = ({
  iconName,
  onClick,
}: {
  iconName: IconProps["name"];
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className="rounded-full bg-transparent hover:opacity-80"
    >
      <Icon name={iconName} size={50} />
    </button>
  );
};
