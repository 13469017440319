import { useMemo } from "react";
import { useTheme } from "../hooks/useTheme";

export interface IconProps {
  name:
    | "microphone"
    | "microphone-slash"
    | "phone"
    | "video"
    | "video-slash"
    | "switch"
    | "settings"
    | "share-screen"
    | "share-screen-slash"
    | "close"
    | "poor-connection";
  size?: number;
  color?: string;
}

export const Icon = ({ name, size = 28, color: colorProp }: IconProps) => {
  const theme = useTheme();
  const color = !!colorProp ? colorProp : theme.theme.colors.primary.DEFAULT;

  const iconElement = useMemo(() => {
    switch (name) {
      case "video-slash":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
          >
            <g id="camera_off" transform="translate(-624 -646)">
              <path
                id="Path_82"
                data-name="Path 82"
                d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z"
                transform="translate(624 646)"
                fill="#d93025"
              ></path>
              <g
                id="Group_52"
                data-name="Group 52"
                transform="translate(-65.379 324.177)"
              >
                <line
                  id="Line_5"
                  data-name="Line 5"
                  x2="27.788"
                  y2="28.38"
                  transform="translate(700.485 332.824)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></line>
                <path
                  id="Path_58"
                  data-name="Path 58"
                  d="M725.65,351.162l-6.207,2.091V352.5a2.828,2.828,0,0,0-2.729-2.917H703.108a2.828,2.828,0,0,0-2.729,2.917v12a2.828,2.828,0,0,0,2.729,2.917h13.606a2.828,2.828,0,0,0,2.729-2.917v-.757l6.207,2.091c1.448.5,2.729-1.306,2.729-2.917v-8.83C728.379,352.468,727.277,350.661,725.65,351.162Z"
                  transform="translate(0 -11.481)"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                ></path>
              </g>
            </g>
          </svg>
        );
      case "video":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
          >
            <g id="camera_on" transform="translate(-552 -646)">
              <g
                id="Ellipse_23"
                data-name="Ellipse 23"
                transform="translate(552 646)"
                fill="#fff"
                stroke="#cecece"
                strokeWidth="1"
              >
                <circle cx="25" cy="25" r="25" stroke="none"></circle>
                <circle cx="25" cy="25" r="24.5" fill="none"></circle>
              </g>
              <path
                id="Path_64"
                data-name="Path 64"
                d="M606.056,351.161l-6.19,2.09v-.756a2.823,2.823,0,0,0-2.721-2.916H583.578a2.823,2.823,0,0,0-2.721,2.916v11.99a2.823,2.823,0,0,0,2.721,2.916h13.567a2.823,2.823,0,0,0,2.721-2.916v-.757l6.19,2.09c1.444.5,2.721-1.305,2.721-2.916v-8.826C608.777,352.466,607.678,350.661,606.056,351.161Z"
                transform="translate(-17.357 312.921)"
                fill="none"
                stroke="#333"
                strokeMiterlimit="10"
                strokeWidth="2"
              ></path>
            </g>
          </svg>
        );
      case "switch":
        return (
          <svg width={size} height={size} viewBox="0 0 28 28" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.294 16.8H27.3C27.692 16.8 28 17.108 28 17.5V23.52C28 24.136 27.258 24.458 26.81 24.01L25.2 22.4C22.652 25.788 18.578 28 14 28C7.89601 28 2.70201 24.094 0.784012 18.634C0.476012 17.738 1.17601 16.8 2.12801 16.8C2.4125 16.7976 2.69094 16.8821 2.92611 17.0422C3.16128 17.2023 3.34197 17.4304 3.44401 17.696C4.97001 22.064 9.11401 25.2 14 25.2C17.808 25.2 21.14 23.282 23.17 20.356L20.804 17.99C20.356 17.556 20.664 16.8 21.294 16.8ZM4.83001 7.63L7.21001 10.01C7.30675 10.1087 7.37212 10.2338 7.39786 10.3696C7.42361 10.5054 7.40859 10.6457 7.35469 10.773C7.3008 10.9003 7.21043 11.0087 7.095 11.0847C6.97956 11.1607 6.84421 11.2008 6.70601 11.2H0.700012C0.308012 11.2 1.2082e-05 10.892 1.2082e-05 10.5V4.494C-0.000799728 4.3558 0.0393153 4.22045 0.115302 4.10501C0.191288 3.98958 0.299745 3.89921 0.427004 3.84532C0.554263 3.79142 0.69463 3.7764 0.830412 3.80214C0.966195 3.82789 1.09132 3.89326 1.19001 3.99L2.80001 5.6C5.34801 2.212 9.42201 0 14 0C20.104 0 25.298 3.906 27.216 9.366C27.524 10.262 26.824 11.2 25.872 11.2C25.298 11.2 24.752 10.85 24.556 10.304C23.03 5.936 18.886 2.8 14 2.8C10.192 2.8 6.86001 4.718 4.83001 7.63Z"
              fill={color}
            />
          </svg>
        );

      case "microphone":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
          >
            <g id="microphone_on" transform="translate(-622 -646)">
              <g
                id="Ellipse_22"
                data-name="Ellipse 22"
                transform="translate(622 646)"
                fill="#fff"
                stroke="#cecece"
                strokeWidth="1"
              >
                <circle cx="25" cy="25" r="25" stroke="none"></circle>
                <circle cx="25" cy="25" r="24.5" fill="none"></circle>
              </g>
              <g id="Group_40" data-name="Group 40" transform="translate(0 -1)">
                <g
                  id="Group_19"
                  data-name="Group 19"
                  transform="translate(168.309 457.049)"
                >
                  <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M489.037,257.5a9.979,9.979,0,0,1-9.979,9.979h-.387a9.979,9.979,0,0,1-9.979-9.979"
                    transform="translate(0 -43.152)"
                    fill="none"
                    stroke="#333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="12.155"
                    height="19.697"
                    rx="6.077"
                    transform="translate(472.786 200.951)"
                    strokeWidth="2"
                    stroke="#333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></rect>
                  <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M511.624,301.64v4.328"
                    transform="translate(-32.76 -76.831)"
                    fill="none"
                    stroke="#333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        );
      case "microphone-slash":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
          >
            <g id="microphone_off" transform="translate(-693 -646)">
              <path
                id="Path_83"
                data-name="Path 83"
                d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z"
                transform="translate(693 646)"
                fill="#d93025"
              ></path>
              <g
                id="Group_51"
                data-name="Group 51"
                transform="translate(227.818 437.064)"
              >
                <path
                  id="Path_56"
                  data-name="Path 56"
                  d="M500.352,278.192a9.849,9.849,0,0,1-9.849,9.849h-.382a9.849,9.849,0,0,1-9.849-9.849"
                  transform="translate(-0.069 -44.633)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
                <rect
                  id="Rectangle_13"
                  data-name="Rectangle 13"
                  width="11.996"
                  height="19.439"
                  rx="5.998"
                  transform="translate(484.245 219.935)"
                  strokeWidth="2"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                ></rect>
                <path
                  id="Path_57"
                  data-name="Path 57"
                  d="M523.206,322.329V326.6"
                  transform="translate(-32.963 -78.449)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
                <line
                  id="Line_4"
                  data-name="Line 4"
                  x2="20.724"
                  y2="21.165"
                  transform="translate(480.182 219.977)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></line>
              </g>
            </g>
          </svg>
        );
      case "phone":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 50 50"
          >
            <g id="leave_call" transform="translate(-688 -646)">
              <g
                id="Ellipse_25"
                data-name="Ellipse 25"
                transform="translate(688 646)"
                fill="#fff"
                stroke="#cecece"
                strokeWidth="1"
              >
                <circle cx="25" cy="25" r="25" stroke="none"></circle>
                <circle cx="25" cy="25" r="24.5" fill="none"></circle>
              </g>
              <g
                id="Group_39"
                data-name="Group 39"
                transform="translate(735.966 672.41) rotate(135)"
              >
                <path
                  id="Path_24"
                  data-name="Path 24"
                  d="M19.077,26.686l.012.007a5.692,5.692,0,0,0,7.082-.777l.8-.8a1.9,1.9,0,0,0,0-2.684l-3.355-3.353a1.9,1.9,0,0,0-2.684,0h0a1.9,1.9,0,0,1-2.683,0l-5.368-5.369a1.9,1.9,0,0,1,0-2.684h0a1.9,1.9,0,0,0,0-2.683L9.524,4.99a1.9,1.9,0,0,0-2.684,0l-.8.8a5.694,5.694,0,0,0-.778,7.082l.008.012a51.371,51.371,0,0,0,13.8,13.806Z"
                  transform="translate(0 0)"
                  fill="none"
                  stroke="#d93025"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
              </g>
            </g>
          </svg>
        );

      case "settings":
        return (
          <svg
            viewBox="0 0 140 140"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            style={{
              height: `${size}px`,
              width: `${size}px`,
              padding: "10px 0px",
              border: "1px solid rgb(206, 206, 206)",
              borderRadius: "50%",
              backgroundColor: "rgb(255, 255, 255)",
            }}
          >
            <path
              d="m52.794 23.968 4.2-10.808A9.688 9.688 0 0 1 66.066 7h7.868a9.688 9.688 0 0 1 9.03 6.16l4.2 10.808 14 8.12 11.48-1.736a9.688 9.688 0 0 1 9.8 4.732l3.948 6.832a9.702 9.702 0 0 1-.826 10.892l-7.238 9.072v16.24l7.238 9.072a9.702 9.702 0 0 1 .826 10.892l-3.948 6.832a9.688 9.688 0 0 1-9.8 4.732l-11.48-1.736-14 8.12-4.2 10.808a9.688 9.688 0 0 1-9.03 6.16h-7.868a9.688 9.688 0 0 1-9.03-6.16l-4.2-10.808-14-8.12-11.48 1.736a9.688 9.688 0 0 1-9.8-4.732l-4.088-6.832a9.702 9.702 0 0 1 .826-10.892l7.238-9.072V61.88l-7.238-9.072a9.702 9.702 0 0 1-.826-10.892l3.948-6.832a9.688 9.688 0 0 1 9.8-4.732l11.48 1.736zM52.5 70A17.5 17.5 0 1 0 70 52.5 17.5 17.5 0 0 0 52.5 70z"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="10"
            ></path>
          </svg>
        );

      case "share-screen":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
          >
            <g transform="translate(465 2117)">
              <g
                transform="translate(-465 -2117)"
                style={{
                  fill: "rgb(255, 255, 255)",
                  stroke: "rgb(206, 206, 206)",
                }}
              >
                <circle
                  cx="25"
                  cy="25"
                  r="25"
                  style={{ stroke: "none" }}
                ></circle>
                <circle
                  cx="25"
                  cy="25"
                  r="24.5"
                  style={{ fill: "none" }}
                ></circle>
              </g>
              <g transform="translate(-646.5 -2450)">
                <line
                  y2="17.262"
                  transform="translate(193.5 348.392)"
                  style={{
                    fill: "none",
                    stroke: "rgb(51, 51, 51)",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  x1="25"
                  transform="translate(194 348)"
                  style={{
                    fill: "none",
                    stroke: "rgb(51, 51, 51)",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  y1="17.262"
                  transform="translate(219 348.392)"
                  style={{
                    fill: "none",
                    stroke: "rgb(51, 51, 51)",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  x1="7.062"
                  transform="translate(193.5 365.654)"
                  style={{
                    fill: "none",
                    stroke: "rgb(51, 51, 51)",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  x2="7.062"
                  transform="translate(211.938 365.654)"
                  style={{
                    fill: "none",
                    stroke: "rgb(51, 51, 51)",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  y2="12.077"
                  transform="translate(206.839 357.5)"
                  style={{
                    fill: "none",
                    stroke: "rgb(51, 51, 51)",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <g transform="translate(202.523 356.689)">
                  <line
                    y2="6.125"
                    transform="translate(4.331 0) rotate(45)"
                    style={{
                      fill: "none",
                      stroke: "rgb(51, 51, 51)",
                      strokeLinecap: "round",
                      strokeWidth: 2,
                    }}
                  ></line>
                  <line
                    y1="6.125"
                    transform="translate(8.706 4.331) rotate(135)"
                    style={{
                      fill: "none",
                      stroke: "rgb(51, 51, 51)",
                      strokeLinecap: "round",
                      strokeWidth: 2,
                    }}
                  ></line>
                </g>
                <line
                  x2="11.769"
                  transform="translate(200.562 370.362)"
                  style={{
                    fill: "none",
                    stroke: "rgb(51, 51, 51)",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
              </g>
            </g>
          </svg>
        );

      case "share-screen-slash":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
          >
            <g transform="translate(465 2117)">
              <g
                transform="translate(-465 -2117)"
                style={{
                  fill: "rgb(255, 255, 255)",
                  stroke: "rgb(206, 206, 206)",
                }}
              >
                <circle
                  cx="25"
                  cy="25"
                  r="25"
                  style={{ stroke: "none" }}
                ></circle>
                <circle
                  cx="25"
                  cy="25"
                  r="24.5"
                  style={{ fill: "none" }}
                ></circle>
              </g>
              <g transform="translate(-646.5 -2450)">
                <line
                  y2="17.262"
                  transform="translate(193.5 348.392)"
                  style={{
                    fill: "none",
                    stroke: "#d93025",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  x1="25"
                  transform="translate(194 348)"
                  style={{
                    fill: "none",
                    stroke: "#d93025",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  y1="17.262"
                  transform="translate(219 348.392)"
                  style={{
                    fill: "none",
                    stroke: "#d93025",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  x1="7.062"
                  transform="translate(193.5 365.654)"
                  style={{
                    fill: "none",
                    stroke: "#d93025",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  x2="7.062"
                  transform="translate(211.938 365.654)"
                  style={{
                    fill: "none",
                    stroke: "#d93025",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <line
                  y2="12.077"
                  transform="translate(206.839 357.5)"
                  style={{
                    fill: "none",
                    stroke: "#d93025",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
                <g transform="translate(202.523 356.689)">
                  <line
                    y2="6.125"
                    transform="translate(4.331 0) rotate(45)"
                    style={{
                      fill: "none",
                      stroke: "#d93025",
                      strokeLinecap: "round",
                      strokeWidth: 2,
                    }}
                  ></line>
                  <line
                    y1="6.125"
                    transform="translate(8.706 4.331) rotate(135)"
                    style={{
                      fill: "none",
                      stroke: "#d93025",
                      strokeLinecap: "round",
                      strokeWidth: 2,
                    }}
                  ></line>
                </g>
                <line
                  x2="11.769"
                  transform="translate(200.562 370.362)"
                  style={{
                    fill: "none",
                    stroke: "#d93025",
                    strokeLinecap: "round",
                    strokeWidth: 2,
                  }}
                ></line>
              </g>
            </g>
          </svg>
        );

      case "close":
        return (
          <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width={size}
            height={size}
          >
            <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
          </svg>
        );

      case "poor-connection":
        return (
          <svg
            width={size}
            height={size}
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2291_22997)">
              <path
                d="M74.57 149.14C115.754 149.14 149.14 115.754 149.14 74.57C149.14 33.3861 115.754 0 74.57 0C33.3861 0 0 33.3861 0 74.57C0 115.754 33.3861 149.14 74.57 149.14Z"
                fill="#D6382E"
              />
              <path
                d="M62.7605 36.75C62.9505 40.69 63.1405 44.63 63.3205 48.58C58.8105 49.12 51.3705 50.57 43.3305 55.06C35.6805 59.33 30.6705 64.56 27.8405 68.01C24.9405 69.43 21.5105 68.66 19.6705 66.32C18.1405 64.37 17.9205 61.61 19.1105 59.28C22.8505 55.25 28.5605 50 36.5705 45.48C46.9405 39.63 56.6105 37.56 62.7605 36.75V36.75Z"
                fill="white"
              />
              <path
                d="M86.3706 36.75C86.1806 40.69 85.9905 44.63 85.8105 48.58C90.3205 49.12 97.7606 50.57 105.801 55.06C113.451 59.33 118.461 64.56 121.291 68.01C124.191 69.43 127.621 68.66 129.461 66.32C130.991 64.37 131.211 61.61 130.021 59.28C126.281 55.25 120.571 50 112.561 45.48C102.191 39.63 92.5206 37.56 86.3706 36.75V36.75Z"
                fill="white"
              />
              <path
                d="M63.6203 62.22C63.8103 66.16 64.0003 70.1 64.1803 74.05C61.0203 75.05 56.8203 76.78 52.4303 79.83C49.5203 81.85 47.2303 83.97 45.4803 85.84C42.9503 87.15 39.8903 86.67 37.9703 84.71C36.2603 82.96 35.7403 80.3 36.6603 77.95C38.8703 75.17 42.7903 70.93 48.8603 67.44C54.7203 64.07 60.1503 62.77 63.6103 62.23L63.6203 62.22Z"
                fill="white"
              />
              <path
                d="M86.3706 62.22C86.1806 66.16 85.9905 70.1 85.8105 74.05C88.9705 75.05 93.1705 76.78 97.5605 79.83C100.471 81.85 102.761 83.97 104.511 85.84C107.041 87.15 110.101 86.67 112.021 84.71C113.731 82.96 114.251 80.3 113.331 77.95C111.121 75.17 107.201 70.93 101.131 67.44C95.2705 64.07 89.8405 62.77 86.3805 62.23L86.3706 62.22Z"
                fill="white"
              />
              <path
                d="M64.6215 86.5701C64.8115 90.5101 65.0015 94.4501 65.1815 98.4001C64.9715 99.1301 64.5715 100.17 63.7815 101.24C62.6015 102.84 61.1715 103.67 60.3815 104.05C57.1815 105.17 53.8815 103.87 52.6815 101.41C51.4315 98.8401 53.0215 96.1301 53.1515 95.9201C54.1715 94.5201 55.5515 92.8901 57.3815 91.2701C60.0215 88.9301 62.6315 87.4701 64.6215 86.5601V86.5701Z"
                fill="white"
              />
              <path
                d="M85.0112 86.5701C84.8212 90.5101 84.6312 94.4501 84.4512 98.4001C84.6612 99.1301 85.0612 100.17 85.8512 101.24C87.0312 102.84 88.4612 103.67 89.2512 104.05C92.4512 105.17 95.7512 103.87 96.9512 101.41C98.2012 98.8401 96.6112 96.1301 96.4812 95.9201C95.4612 94.5201 94.0812 92.8901 92.2512 91.2701C89.6112 88.9301 87.0012 87.4701 85.0112 86.5601V86.5701Z"
                fill="white"
              />
              <path
                d="M69.4105 97.69C70.2405 100.18 72.6105 101.78 75.0705 101.57C77.1605 101.4 78.9905 99.94 79.7405 97.88C80.4905 75.92 81.2405 53.96 81.9905 32C81.8805 31.78 79.8905 27.9 75.6105 27.5C72.3905 27.2 69.2405 28.99 67.7305 32C68.2905 53.9 68.8605 75.8 69.4205 97.7L69.4105 97.69Z"
                fill="white"
              />
              <path
                d="M74.8597 121.68C79.2393 121.68 82.7897 118.13 82.7897 113.75C82.7897 109.37 79.2393 105.82 74.8597 105.82C70.4801 105.82 66.9297 109.37 66.9297 113.75C66.9297 118.13 70.4801 121.68 74.8597 121.68Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2291_22997">
                <rect width="149.13" height="149.13" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );

      default:
        return null;
    }
  }, [color, name, size]);

  return iconElement;
};
