module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    colors: {
      primary: {
        DEFAULT: "#337ab7",
        light: "#5ac8fa",
        dark: "#007aff",
        disabledColor: "#E3E6E8",
        disabledDarkColor: "#99A1A8",
        headerTextColor: "#313030",
      },
      secondary: {
        DEFAULT: "#66B76F",
        darkColor: "#66B76F",
      },
      danger: "#FF0000",
      white: "#FFFFFF",
      black: "#000000",
      background: { primary: "#FEFEFE", secondary: "#FFFFFF" },
      transparent: "rgba(0,0,0,0)",
    },

    extend: {
      spacing: {
        s: "1rem",
        m: "2rem",
        l: "4rem",
        xl: "8rem",
      },
      borderRadius: {
        s: "6rem",
      },
    },
  },
  plugins: [],
};
