interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "medium" | "small";
  status?: "regular" | "danger" | "secondary";
}

export const Button = ({
  size = "medium",
  status = "regular",
  disabled = false,
  className = "",
  ...otherProps
}: ButtonProps) => {
  const backgroundColor =
    status === "danger"
      ? "bg-danger"
      : status === "regular"
      ? "bg-primary"
      : status === "secondary"
      ? "bg-secondary"
      : "bg-primary";

  const textSize =
    size === "medium" ? "text-lg" : size === "small" ? "text-m" : "text-m";
  const disabledStyle = disabled ? "opacity-75" : "";
  return (
    <button
      className={`${backgroundColor} ${textSize} ${disabledStyle} px-8 py-2 rounded-md text-white font-semibold ${className}`}
      disabled={disabled}
      {...otherProps}
    />
  );
};
